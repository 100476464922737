



















































import { User } from "@/models/user.model";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";

const usersNamespace = namespace("Users");
@Component({})
export default class MemberProfile extends Vue {
  isLoading = false;

  @usersNamespace.Getter("current")
  user!: User;

  @usersNamespace.Action("fetchPublicById")
  fetchPublicById!: (id: string) => Promise<User>;

  get userInitials() {
    return `${this.user?.first_name?.charAt(0)}${this.user?.last_name?.charAt(
      0
    )}`;
  }

  get profilePicture() {
    return this.user?.profile_image_url;
  }

  async doFetch() {
    this.isLoading = true;
    await this.fetchPublicById(this.$route.params.id);
    this.isLoading = false;
  }

  async created() {
    await this.doFetch();
  }
}
